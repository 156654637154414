import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import App from "../components/App";
import Frame from "../components/Frame";
import SEO from "../components/SEO";

export default ({ location }) => (
  <App>
    <Header />
    <SEO page={{ title: "About", pathname: location.pathname }} />
    <Frame>
      <Content>
        <h1>About</h1>
        <p>I live in Wrocław, Poland. I work as a Principal Software Engineer and Tech Lead for DevTools team at <a href="https://rho.co">Rho</a>.</p>
        <p>In the software engineering field, my main areas of interest are software architecture and testing. I&nbsp;now mostly write code in TypeScript/JavaScript and Golang. I'm not a stranger to Kubernetes and moder cloud infrastructure. I&nbsp;have working experience with projects in Python, Elixir, Ruby, and Ethereum Blockchain (Solidity).</p>
        <p><strong>Follow me on <a href="https://twitter.com/MichalZalecki">Twitter</a></strong>, where I&nbsp;share some interesting, bite-size content.</p>
        <p>You can find me on <a href="https://github.com/MichalZalecki">GitHub</a> and <a href="https://www.linkedin.com/in/michal-zalecki/">LinkedIn</a>.</p>
      </Content>
    </Frame>
    <Footer />
  </App>
);

const Content = styled.div`
  padding: 26px 0 50px;
  grid-column: 5 / 19 !important;
  @media (max-width: 736px) {
    grid-column: 2 / 24 !important;
  }
`;
